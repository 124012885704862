import { Component, Input } from '@angular/core';
import { Subscription } from 'src/app/helpers/interfaces/api/subscription-api.interface';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'subscription',
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.css',
})
export class SubscriptionComponent {

  @Input()
  subscriptions: Subscription[] = [];

  constructor(private subscriptionService: SubscriptionService) {}

  ngOnInit(): void {
  }
}
