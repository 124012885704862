import { Component, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Message } from 'primeng/api';
import { StripeSubscriptionComponent } from 'src/app/components/stripe-subscription/stripe-subscription.component';
import {
  ConfirmBoxInitializer,
  DialogLayoutDisplay,
  DisappearanceAnimation,
  AppearanceAnimation,
} from '@costlydeveloper/ngx-awesome-popup';
import { Router } from '@angular/router';
import { Subscription } from 'src/app/helpers/interfaces/api/subscription-api.interface';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  templateUrl: './suscription-page.component.html',
  styleUrl: './suscription-page.component.css',
})
export class SubscriptionPageComponent {
  @ViewChild(StripeSubscriptionComponent)
  stripeComponent!: StripeSubscriptionComponent;
  subscriptions: Subscription[] = [];

  public submitActive: boolean = false;
  messages: Message[] = [];
  public isMobile: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 650;

    this.subscriptionService.getSubscriptions().subscribe({
      next: (data) => {
        if (data.status == 1) {
          this.subscriptions = data.response;
        }
      },
    });
  }

  navigateToHomeAndScroll(sectionId: string): void {
    this.router.navigate(['/'], {
      queryParams: { s: sectionId },
    });
  }

  async payStripe() {
    this.subscriptionService.subscription = this.subscriptions
    this.spinner.show();
    try {
      const paymentId = await this.stripeComponent.pay();
      if (paymentId) {
        this.messages = [
          { severity: 'success', detail: 'Subscription completed!' },
        ];
        this.openSubscriptionSuccess();
      }
    } catch (error) {
      this.toastr.error('' + error);
      this.messages = [{ severity: 'error', detail: '' + error }];
      this.spinner.hide();
    }
    this.spinner.hide();
  }

  openSubscriptionSuccess() {
    const newConfirmBox = new ConfirmBoxInitializer();

    newConfirmBox.setTitle('Subscription success!!');
    newConfirmBox.setMessage(`
        <div class="flex w-full flex-col items-center gap-5 justify-center">
          <h1 class="text-xl lg:text-2xl font-bold">Your membership has been successfully activated! <br> You now have access to all the benefits. Enjoy! 🎉</h1>
        </div>
      `);
    // Choose layout color type
    newConfirmBox.setConfig({
      layoutType: DialogLayoutDisplay.CUSTOM_ONE, // SUCCESS | INFO | NONE | DANGER | WARNING
      animationIn: AppearanceAnimation.SLIDE_IN_DOWN, // BOUNCE_IN | SWING | ZOOM_IN | ZOOM_IN_ROTATE | ELASTIC | JELLO | FADE_IN | SLIDE_IN_UP | SLIDE_IN_DOWN | SLIDE_IN_LEFT | SLIDE_IN_RIGHT | NONE
      animationOut: DisappearanceAnimation.SLIDE_OUT_DOWN, // BOUNCE_OUT | ZOOM_OUT | ZOOM_OUT_WIND | ZOOM_OUT_ROTATE | FLIP_OUT | SLIDE_OUT_UP | SLIDE_OUT_DOWN | SLIDE_OUT_LEFT | SLIDE_OUT_RIGHT | NONE
      allowHtmlMessage: true,
      disableIcon: true,
      buttonPosition: 'center', // optional
      width: this.isMobile ? '90%' : '35%',
      height: this.isMobile ? '50%' : '40%',
      customStyles: {
        buttonCSS: `
          color: white !important;
          transition-property: all;
          border-radius: .5rem;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 300ms;
          padding-left: 1.2rem;
          padding-right: 1.2rem;
          height: 2.7rem;
          border: 1px;
          width: 9rem;
          border-color: rgb(55 65 81);
          box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.20);
          font-size: 1rem !important;
        `,
      },
    });

    newConfirmBox.setButtonLabels('Close', '');

    newConfirmBox.openConfirmBox$().subscribe((resp) => {
      if (resp.clickedButtonID) {
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 1000);
      }
    });
  }
}
