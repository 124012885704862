
<img src="../../../assets/images/another-tag-new.png" alt="" class="absolute top-0 right-0 w-16 h-16 lg:w-20 lg:h-20 z-50">

<div mat-dialog-title class="dialogTitleClass bg-primary-700 p-6 justify-center items-center">
  <div class="flex flex-col items-center justify-center gap-1">
    <h1 class="text-xl lg:text-3xl text-white text-center font-semibold">{{ data.nombre }}</h1>
  </div>
</div>
<div mat-dialog-content class="dialogContentClass">
  <div class="flex flex-col justify-center items-center gap-3 mt-5">
    <div class="w-full flex justify-center">
      <h2 class="text-2xl lg:text-4xl font-semibold text-gray-950 w-3/4 text-center">GET ALL MEALS AT {{ data.Beneficios[0].valor | currency: 'USD':'symbol':'1.2-2' }} EACH</h2>
    </div>

    <div class="w-full flex flex-col">
      <div class="text-base lg:text-xl flex items-center p-1" *ngFor="let ben of data.Beneficios">
        <span class="transition-all duration-300 ease-in-out w-6 h-6 lg:w-8 lg:h-8 group-hover:border-2 rounded-full flex items-center justify-center bg-white">
          <mat-icon class="text-primary-700 text-lg text-center">check</mat-icon>
        </span>
        <h1 class="ml-3 font-serif">{{ ben.descripcion }}</h1>
      </div>
    </div>

    <div class="flex flex-col w-full gap-4 items-center">
      <button mat-raised-button mat-dialog-close color="primary" class="btn btnSubscribe" routerLink="/membership">SUBSCRIBE NOW</button>
    </div>
    <div>
       <button mat-button mat-dialog-close class="text-sm text-gray-500 text-center btnSkip">Maybe later</button>
    </div>

  </div>

</div>

