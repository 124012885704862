import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SubscriptionPageComponent } from './pages/subscription-page/suscription-page.component';
import { SignInPageComponent } from './modules/auth/pages/sign-in-page/sign-in-page.component';
import { SignUpPageComponent } from './modules/auth/pages/sign-up-page/sign-up-page.component';
import { RecoveryPasswordComponent } from './modules/auth/pages/recovery-password/recovery-password.component';
import { ChangePasswordRecoveryComponent } from './modules/auth/pages/change-password-recovery/change-password-recovery.component';
import { ValidOtpComponent } from './modules/auth/pages/valid-otp/valid-otp.component';
import { isAuthenticatedClientGuard } from './guards';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { title: 'MG Meals', name: 'MG Meals' },
  },
  {
    path: 'order',
    canActivate: [isAuthenticatedClientGuard],
    loadChildren: () =>
      import('./modules/fit-meals-v2/fit-meals-v2.module').then(
        (m) => m.FitMealsV2Module
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'sign-in',
    component: SignInPageComponent,
    data: { title: 'Sign In | MG Meals', name: 'Sign In | MG Meals' },
  },
  {
    path: 'sign-up',
    component: SignUpPageComponent,
    data: { title: 'Sign Up', name: 'Sign Up' },
  },
  {
    path: 'recovery',
    component: RecoveryPasswordComponent,
    data: {
      title: 'Recovery Password | MG Meals',
      name: 'Recovery Password | MG Meals',
    },
  },
  {
    path: 'confirm-otp',
    component: ValidOtpComponent,
    data: {
      title: 'Recovery Password | MG Meals',
      name: 'Recovery Password | MG Meals',
    },
  },
  {
    path: 'recovery-password',
    component: ChangePasswordRecoveryComponent,
    data: {
      title: 'Recovery Password | MG Meals',
      name: 'Recovery Password | MG Meals',
    },
  },
  {
    path: 'profile',
    canActivate: [isAuthenticatedClientGuard],
    loadChildren: () =>
      import('./modules/client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'membership',
    canActivate: [isAuthenticatedClientGuard],
    component: SubscriptionPageComponent,
    data: {
      title: 'Membership | MG Meals',
      name: 'Membership | MG Meals',
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
