import { Component, EventEmitter, Output, ViewChild, inject, signal } from '@angular/core';
import { StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { StripePaymentElementComponent, injectStripe } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { ProcessSubscription, ProcessSubscriptionRequest } from 'src/app/helpers/interfaces/api/subscription-api.interface';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'stripe-subscription',
  templateUrl: './stripe-subscription.component.html',
  styleUrl: './stripe-subscription.component.css',
})
export class StripeSubscriptionComponent {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;
  paymentId!: string | null;

  private subscriptionService = inject(SubscriptionService);
  private userService = inject(UserService);

  isMobile: boolean = false;
  public submitActive: boolean = false;

  @Output()
  private loadStripe = new EventEmitter<boolean>();

  constructor(private toastr: ToastrService) {
    this.isMobile = window.innerWidth < 650;
  }

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    mode: 'subscription',
    amount: 1999,
    currency: 'usd',
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimary: '#ff9d00',
        colorText: '#374151',
        colorDanger: '#df1b41',
        fontFamily: 'Roobert, Roboto, sans-serif',
      },
    },
  };

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: true,
      radios: false,
      spacedAccordionItems: true,
    },
    defaultValues: {
      billingDetails: {
        name: '',
      },
    },
  };

  // Replace with your own public key
  stripe = injectStripe(environment.pkStripe);
  paying = signal(false);
  paymentSucced: boolean = false;

  ngOnInit() {}

  load(value: boolean): void {
    this.loadStripe.emit(value);
  }

  pay() {
    return new Promise(async (resolve, reject) => {
      if (this.paying()) reject('Payment is already in progress');

      if (!this.stripe) {
        this.paying.set(false);
        return reject('Stripe dont load yet!');
      }

      this.paying.set(true);
      const { error: submitError } =
        await this.paymentElement.elements.submit();

      if (submitError) {
        this.paying.set(false);
        return reject('Please verify the information and try again.');
      }
      const newSubscription: ProcessSubscriptionRequest = {
        idCliente: this.userService.user?.idClient ?? 0,
        idProduct: this.subscriptionService.subscription[0].idSuscripcion,
      };

      this.subscriptionService.processSubscription(newSubscription).subscribe({
        next: (data) => {

          if (data.status == 1) {
            if (typeof data.response == 'object') {
              const idSubscription = data.response.idCLienteSuscripcion
              this.stripe
                .confirmPayment({
                  elements: this.paymentElement.elements,
                  clientSecret:
                    data.response.id,
                  confirmParams: {
                    save_payment_method: true,
                    return_url: 'https://zycoffeeteam.com',
                  },
                  redirect: 'if_required',
                })
                .subscribe((result) => {
                  this.paying.set(false);
                  if (result.error) {
                    this.paymentSucced = false;
                    this.paymentId = null;
                    reject(result.error.message);
                  } else {
                    if (result.paymentIntent.status === 'succeeded') {
                      this.paymentSucced = true;
                      this.paymentId = result.paymentIntent.id;
                      this.toastr.success('Successful Payment!');
                      this.subscriptionService.confirmSubscription({
                        idCliente: this.userService.user?.idClient ?? 0,
                        idCLienteSuscripcion: idSubscription
                      })
                      .subscribe({
                        next: (data) => {
                          if (data.status == 1) {
                            resolve(this.paymentId);
                          } else {
                            reject(data.response);
                          }
                        },
                        error: (error) => {
                          reject(error)
                        }
                      })
                    }
                  }
                });
            }
          } else {
            this.paying.set(false);
            if (typeof data.response == 'string') {
              this.toastr.error(data.response);
              return reject(data.response);
            }
          }
        },
        error: (error) => {
          console.error(error);

          this.paying.set(false);
        },
      });
    });
  }

  confirmPayment(data: ProcessSubscription) {

  }

  // splitAddress(address: string) {
  //   let parts = address.split(', ');
  //   let line1 = parts[0];
  //   let city = parts[1];
  //   let postalCode = parts[2].split(' ')[1];

  //   return {
  //     'Line1': line1,
  //     'city': city,
  //     'PostalCode': postalCode
  //   };
  // }
}
