import { ViewportScroller } from '@angular/common';
import { Component, Renderer2, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, map } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Home | MG Meals';

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private viewportScroller: ViewportScroller,
    private renderer: Renderer2
  ) {
    this.adjustBodyHeight();
    window.addEventListener('resize', () => this.adjustBodyHeight());

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(data);
        }
        this.viewportScroller.scrollToPosition([0, 0]);
      });
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

  adjustBodyHeight() {
    const height = window.innerHeight + 'px';
    const body = document.body;
    this.renderer.setStyle(body, 'height', height);
  }
}
