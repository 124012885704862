<header-page class="w-full" (linkClicked)="navigateToHomeAndScroll($event)"></header-page>

<div class="flex justify-center mt-5">
  <div class="w-full px-6 lg:w-10/12 lg:px-0 flex gap-4 min-h-128">
    <div class="w-full flex flex-col pt-6 gap-5 justify-start">
      <back class="w-20"></back>
      <div class="w-full flex flex-col lg:flex-row gap-6 justify-between">
        <div class="flex flex-col gap-5 w-full lg:w-6/12 pb-5 order-2 lg:order-1">
          <h2 class="font-sans text-2xl lg:text-4xl font-bold text-gray-700">Membership</h2>
          <stripe-subscription (loadStripe)="submitActive = true"></stripe-subscription>
          <button mat-raised-button color="primary" (click)="payStripe()" *ngIf="submitActive">Subscribe</button>
          <p-messages [(value)]="messages" [enableService]="false" closable="false"/>
        </div>
        <div class="order-1 lg:order-2 w-full lg:w-5/12 bg-primary-700 flex flex-col py-10 px-5 gap-4 rounded-lg shadow-lg mb-5">
          <div class="flex flex-col gap-1">
            <h2 class="text-white font-semibold text-3xl font-serif">{{ subscriptions[0].nombre }}</h2>
            <div class="flex items-end">
              <h3 class="text-white font-semibold text-2xl font-serif">{{ subscriptions[0].costo | currency: 'USD':'symbol':'1.2-2' }}</h3>
              <small class="text-white text-lg font-light">/</small>
              <small class="text-white text-lg font-light ml-1">
                month
              </small>
            </div>
          </div>
          <div class="flex flex-col gap-0.5 text-white">
            <div class="text-base lg:text-xl flex items-center" *ngFor="let ben of subscriptions[0].Beneficios">
              <span class="transition-all duration-300 ease-in-out w-6 h-6 lg:w-8 lg:h-8 group-hover:border-2 rounded-full flex items-center justify-center">
                <mat-icon class="text-white text-lg text-center">check</mat-icon>
              </span>
              <h1 class="ml-3 font-serif">{{ ben.descripcion }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer-page></footer-page>
