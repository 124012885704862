import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'src/app/helpers/interfaces/api/subscription-api.interface';

@Component({
  selector: 'app-popup-membership',
  templateUrl: './popup-membership.component.html',
  styleUrl: './popup-membership.component.css',
})
export class PopupMembershipComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Subscription) {}
}
