<top-bar></top-bar>

<header-page class="w-full" (linkClicked)="scrollToSection($event)"></header-page>

<home-hero id="hero"></home-hero>
<div class="w-full flex flex-col items-center">
  <about-page class="flex justify-center" id="about"></about-page>
  <plans-page class="w-full" id="plans"></plans-page>
  <call-to-action-order class="w-full"></call-to-action-order>
  <subscription class="w-full" id="membership" [subscriptions]="subscriptions"></subscription>
  <asked class="flex justify-center "></asked>
  <contact class="flex justify-center w-full" id="contact"></contact>
</div>
<footer-page></footer-page>
<scroll-to-top></scroll-to-top>
