import { Component } from '@angular/core';

@Component({
  selector: 'home-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css',
})
export class MenuComponent {
  showFiller = false;
}
