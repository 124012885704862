<section id="hero" class="w-full relative pt-7 hero">
  <div class="relative flex justify-center">
  <div class="container relative h-114">
    <div class="flex items-center justify-center gap-y-3" data-aos="fade-in">
      <div class="w-full order-2 lg:order-1 flex flex-col justify-center text-center lg:text-start items-center">
        <img src="../assets/images/logo-full.png" class="img-fluid w-40" alt="Logo for MG Meals" data-aos="zoom-out" data-aos-delay="100">
        <h2 class="text-3xl lg:text-5xl mt-2 mb-5 font-bold">Made For Your Goals</h2>
        <p class="text-2xl lg:text-3xl font-medium mb-5">Maintain discipline and achieve your goals with <strong class="">MG Meals</strong></p>
        <div class="d-flex justify-content-center justify-content-lg-start">
          <button mat-raised-button color="primary" class="btn-get-started" routerLink="/order">Order now!</button>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="icon-boxes relative pb-14 flex justify-center">
    <div class="container relative w-10/12">
      <div class="grid grid-cols-5 gap-4 mt-5">

        <div class="col-span-5 md:col-span-2 lg:col-span-1" data-aos="fade-up" data-aos-delay="100">
          <a routerLink="/order/plans">
            <div class="icon-box">
              <span class="material-icons-outlined icon">
                ballot
              </span>
              <h4 class="title ">WEEKLY MEALS OR CUSTOM</h4>
              <p class="text-base font-serif font-semibold flex flex-col justify-between h-36 lg:h-44">Choose between our weekly meals or personalized meals, choosing the ingredients to your liking<br>
              <span>CLICK HERE TO SEE OUR MEALS</span></p>
            </div>
          </a>
        </div><!--End Icon Box -->
         <div class="col-span-5 md:col-span-2 lg:col-span-1" data-aos="fade-up" data-aos-delay="200">
          <a routerLink="/membership">
            <div class="icon-box">
              <span class="material-icons-outlined icon">
                loyalty
              </span>
              <h4 class="title ">MEMBERSHIP</h4>
              <p class="text-base font-serif font-semibold flex flex-col justify-between h-36 lg:h-44">Get now ALL ACCESS MEMBERSHIP and enjoy the incredible benefits that we bring to you.
                <br>
                <span>CLICK HERE TO GET IT NOW</span></p>
            </div>
          </a>
        </div><!--End Icon Box -->

        <div class="col-span-5 md:col-span-2 lg:col-span-1" data-aos="fade-up" data-aos-delay="300">
          <a routerLink="/order/plans">
            <div class="icon-box">
              <span class="material-icons-outlined icon">
                delivery_dining
              </span>
              <h4 class="title">WE DELIVER!</h4>
              <p class="text-base font-serif font-semibold mt-0.5 flex flex-col justify-between h-36 lg:h-44">Deliveries are on Mondays<br>
                <span>CLICK HERE TO GET YOUR MEALS DELIVERED!</span>
              </p>
            </div>
          </a>
        </div><!--End Icon Box -->

        <div class="col-span-5 md:col-span-2 lg:col-span-1" data-aos="fade-up" data-aos-delay="400">
          <a routerLink="/order/plans">
            <div class="icon-box">
              <div class="material-icons-outlined icon">
                place
              </div>
              <h4 class="title">PICK UP LOCATIONS</h4>
              <p class="text-base font-serif font-semibold flex flex-col justify-between h-36 lg:h-44">We are in 14 convenient locations where you can pick up your meals for free! <br>
                <span>CLICK HERE: TO SEE ALL OF OUR CONVENIENT LOCATIONS</span>
                </p>
            </div>
          </a>
        </div><!--End Icon Box -->
        <div class="col-span-5 md:col-span-2 lg:col-span-1" data-aos="fade-up" data-aos-delay="500">
          <a routerLink="/order/plans">
            <div class="icon-box">
              <span class="material-icons-outlined icon">
                discount
              </span>
              <h4 class="title ">COUPON DISCOUNT</h4>
              <p class="text-base font-serif font-semibold flex flex-col justify-between h-36 lg:h-44">Redeem the coupon code at checkout and get an incredible discount on your order.
                <br>
                <span>CLICK NOW TO BUY AND GET DISCOUNT</span></p>
            </div>
          </a>
        </div><!--End Icon Box -->

      </div>
    </div>
  </div>
</section>
