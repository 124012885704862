<section id="membership" class="services sections-bg flex justify-center">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h2>Membership</h2>
        <p></p>
      </div>

      <div class="flex flex-col items-center md:flex-row justify-center px-6" data-aos="fade-up" data-aos-delay="100">
          <mat-card class="w-full lg:w-144">
            <mat-card-header class="w-full justify-center">
              <mat-card-title class="w-full">
                <div class="w-full flex justify-center flex-col items-center gap-4 mt-5">
                  <h3 class="text-xl lg:text-2xl font-semibold w-2/3 lg:w-full text-center font-serif">{{ subscriptions[0].nombre }}</h3>
                  <span class="w-12 h-12 rounded-full bg-primary-700 flex justify-center items-center">
                    <mat-icon class="text-white">loyalty</mat-icon>
                  </span>
                  <div class="flex items-end">
                    <h2 class="text-3xl font-semibold font-serif">{{ subscriptions[0].costo | currency: 'USD':'symbol':'1.2-2' }}</h2>
                    <small class="text-gray-500 text-lg font-light">/</small>
                    <small class="text-gray-500 text-lg font-light ml-1">
                      month
                    </small>
                  </div>
                </div>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="my-5">
              <div class="text-base lg:text-xl flex items-center p-1" *ngFor="let ben of subscriptions[0].Beneficios">
                <span class="transition-all duration-300 ease-in-out w-6 h-6 lg:w-8 lg:h-8 group-hover:border-2 rounded-full flex items-center justify-center bg-white">
                  <mat-icon class="text-primary-700 text-lg text-center">check</mat-icon>
                </span>
                <h1 class="ml-3 font-serif">{{ ben.descripcion }}</h1>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <div class="w-full flex flex-col items-center mb-5 gap-2">
                <button
                  mat-stroked-button
                  color="primary"
                  class="btnStrokedPrimary borderDark btnGetNow"
                  routerLink="/membership"
                  >Get now</button>
                <span class="text-xs lg:text-sm">Includes up to 30 meals weekly</span>
              </div>
            </mat-card-actions>
          </mat-card>
      </div>


    </div>
</section>
