import { AfterViewInit, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { PopupMembershipComponent } from 'src/app/components/popup-membership/popup-membership.component';
import { Subscription } from 'src/app/helpers/interfaces/api/subscription-api.interface';
import { LoginClient } from 'src/app/modules/auth/interface/interfaces';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { TokenStorageService } from 'src/app/modules/auth/services/token-storage.service';
import { ClientService } from 'src/app/modules/client/services/client.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements AfterViewInit {
  public session?: LoginClient;
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.getUserSession();

    this.subscriptionService.getSubscriptions().subscribe({
      next: (data) => {
        if (data.status == 1) {
          this.subscriptions = data.response;
        }
      },
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      AOS.init();
      this.route.queryParams.subscribe((params) => {
        const sectionId = params['s'];
        if (sectionId) {
          this.scrollToSection(sectionId);
        }
      });
    }, 100);
    if (!this.userService.clientService.client?.membership) {
      setTimeout(() => {
        this.openPopUpMembership();
      }, 1000);
    }
  }

  navigateToMeals(): void {
    this.router.navigate(['/order']);
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      const rect = element.getBoundingClientRect();
      const offset = 50; // Puedes cambiar este valor según tus necesidades
      window.scrollTo({
        top: rect.top + window.scrollY - offset,
        behavior: 'smooth',
      });
    }
  }

  openPopUpMembership(): void {
    const dialogRef = this.dialog.open(PopupMembershipComponent, {
      enterAnimationDuration: 500,
      data: this.subscriptions[0],
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
