import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from "./helpers/material/material.module";
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';
import { FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import {
  NgxAwesomePopupModule,
  ConfirmBoxConfigModule,
} from '@costlydeveloper/ngx-awesome-popup';
import { HomeComponent } from './pages/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "./interceptor/auth.interceptor";
import { LoadingInterceptor } from "./core/interceptors/spinner.interceptor";
import { SharedModule } from './shared/shared.module';
import { HeroComponent } from './components/sections/hero/hero.component';
import { MenuComponent } from './components/menu/menu.component';
import { AuthClientInterceptor } from './interceptor/auth-client.interceptor';
import { SubscriptionPageComponent } from './pages/subscription-page/suscription-page.component';
import { SubscriptionComponent } from './components/sections/subscription/subscription.component';
import { StripeSubscriptionComponent } from './components/stripe-subscription/stripe-subscription.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environments';
import { PopupMembershipComponent } from './components/popup-membership/popup-membership.component';

@NgModule({
  declarations: [
    AppComponent,
    ThemeSwitcherComponent,
    HomeComponent,
    HeroComponent,
    MenuComponent,
    SubscriptionPageComponent,
    SubscriptionComponent,
    StripeSubscriptionComponent,
    PopupMembershipComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-fade' }),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-left',
      maxOpened: 3,
      progressBar: true,
      titleClass: '',
      messageClass: '',
      preventDuplicates: true,
    }),
    NgxAwesomePopupModule.forRoot({
      colorList: {
        customOne: 'rgba(255,157,0,1)',
      },
    }), // Essential, mandatory main module.
    ConfirmBoxConfigModule.forRoot(), // Needed for instantiating confirm boxes.
    SharedModule,
    NgxStripeModule.forRoot(environment.pkStripe),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthClientInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
