import { DOCUMENT } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.css']
})
export class ThemeSwitcherComponent {
  isDarkThemeActive = false;

  constructor(@Inject(DOCUMENT) private document: Document){}

  onChange(value: boolean): void {
    if (value) {
      this.document.body.classList.add('dark-mode');
    } else {
      this.document.body.classList.remove('dark-mode');
    }
  }
}
